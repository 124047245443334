import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Aguardando from '../common/template/aguardando';

import { setModoTela, getLista } from './reportsIntegracaoVolvoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';

class ReportsIntegracaoVolvo extends Component {

	state = {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}

    componentWillMount() {
        this.props.getLista(this.state.data_inicial, this.state.data_final);
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		return (
			<ContentCard>

				{this.modalArquivo()}
				
				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.state.data_inicial}
							onChange={(data) => {
								this.setState({ ...this.state, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.state.data_final}
							onChange={(data) => {
								this.setState({ ...this.state, data_final: data.target.value });
							}} />

						<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista(this.state.data_inicial, this.state.data_final);
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Shipping</Th>
								<Th alignCenter>Solicitação</Th>
								<Th alignCenter>Data/Hora</Th>
								<Th alignCenter>Registro</Th>
								<Th alignCenter>Retorno</Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {

								if (this.state.pesquisar
									&& !(`${item.shipping_code} ${item.solicitacao} ${DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}
										${item.erro ? 'Sucesso' : 'Erro'}
									`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								
								return true;
							}).map(item => (
								<Tr key={item.id}>
									<Td alignCenter>{item.shipping_code}</Td>
									<Td alignCenter>{item.solicitacao}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter 
										title={''}>
										{item.arquivo &&
										<ButtonTable
											type={'secondary'}
											icon={'fas fa-code'}
											visible={true}
											event={() => {
												this.setState({
													...this.state,
													registroArquivo: {
														...item
													}
												});
											}} />}
									</Td>
									<Td alignCenter>
										{!item.erro ? (
											<span class='badge badge-success' style={{ fontSize: 14, width: 90, padding: 6 }}>Sucesso</span>
										) : (
											<span class='badge badge-danger' title={item.erro} style={{ fontSize: 14, width: 90, padding: 6 }}>Erro: {item.erro}</span>
										)}
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	modalArquivo() {

		if (!this.state.registroArquivo) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>{'Arquivo'}</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, registroArquivo: null, registroErro: null })}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ overflowY: 'scroll', paddingTop: 20 }}>							
							<p style={{ padding: 20, backgroundColor: '#e9ecef', color: '#000', fontSize: 16, minWidth: 800 }}>
								{this.state.registroArquivo.arquivo.split('\n').map(linha => (
									<p style={{ padding: 0, margin: 0 }}>{linha}</p>
								))}
							</p>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.reportsIntegracaoVolvo.modoTela,
	aguardando: state.reportsIntegracaoVolvo.aguardando,
	registro: state.reportsIntegracaoVolvo.registro,
	lista: state.reportsIntegracaoVolvo.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ReportsIntegracaoVolvo);
