import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Aguardando from '../common/template/aguardando';
import { MapLeaflet } from "../common/mapLeaflet/MapLeaflet";
import Cookies from 'js-cookie';

import { 
	setModoTela, setFiltro, setDestinado, getListaFronteiras, getListaNegociadores, getListaOperacoes, getLista, getListaSolicitacoes, getListaOcorrencias
} from './reportsActions';
import Select from '../common/form/select';
import SelectDialog from '../common/form/selectDialog';
import SelectNovo from '../common/form/selectNovo';
import SwitchVertical from '../common/form/switchVertical';

class Reports extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		cardSelecionados: [/*1, 2, 3, 4, 5, 6*/],
		idShippingExpandido: null,
		resumido: true,
		registroModal: null,
		listaFavoritos: JSON.parse(Cookies.get('shippingsFavoritos') || '[]'),
		filtro: {
			apenasNacional: false,
			rumos: [],
			farolAtrasos: [],
			fronteiras: [],
			operacoes: [],
			negociadores: [],
			lote: false,
			classificada: false,
			expressa: false,			
			ocorrenciaAberta: false,
			cavaloEngatado: false,
			somenteCarreta: false,
			semConjunto: false,
			emCD: false,
			semDocumento: false,
			nacional: this.props.usuarioCarregado ? this.props.usuarioCarregado.nacional : false,
			internacional: this.props.usuarioCarregado ? this.props.usuarioCarregado.internacional : false,
			colunasPesquisa:[]
		},
		ordenacao: {
			shipping: 0,
			documentos: 0,
			cavalo: 0,
			carreta: 0,
			lote: 0,
			classificada: 0,
			expressa: 0,
			ocorrencia: 0,
			cliente: 0,
			rota: 0,
			fronteira: 0,
			previsao: 0,
			statusPrevisao: 0,
			posicao: 0,
			statusPosicao: 0,
			destinado: 0
		}
	}

    componentWillMount() {
		this.props.getListaFronteiras();
		this.props.getListaNegociadores();
		this.props.getListaOperacoes();
        this.props.getLista();

		setInterval(() => {
			this.props.getListaFronteiras();
			this.props.getListaNegociadores();
			this.props.getListaOperacoes();
			this.props.getLista();
		}, 120000)
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.lista()}
                </Content>
            </div>
        )
    }

	filtrar(cards) {

		let filtroOperacoes = (this.state.filtro.operacoes || []).map(operacao => 
			({ id: operacao, nome: this.props.listaOperacoes.filter(opeItem => opeItem.id == operacao).map(opeItem => opeItem.valor).join('') })
		);

		let lista = this.props.lista
			//CARDS
			.filter(item => {
				if (cards) {
					return true;
				}
				if (this.isFavorito(item.shipping_code_id)) {
					return true
				}
				return this.state.cardSelecionados.filter(status => status == item.id_status).length > 0;
			})

			.filter(item => {
				if (this.props.usuarioCarregado && (this.props.usuarioCarregado.placas_cco || []).length > 0) {
					return (this.props.usuarioCarregado.placas_cco || []).filter(placa => placa == item.cavalo).length > 0;
				} else {
					return this.props.usuarioCarregado ? true : false;
				}
			})

			.filter(item => {
				if (this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente) {
					return (this.props.usuarioCarregado.id_negociadores || []).filter(negociador => negociador == item.id_negociador).length > 0;
				} else {
					return true;
				}
			})

			//FILTROS SELECT
			.filter(item => {

				if ((this.state.filtro.rumos || []).length && !this.state.filtro.rumos.filter(itemFiltro => itemFiltro == item.rumo).length) {
					return false;
				}
				if ((this.state.filtro.farolAtrasos || []).length 
					&& !this.state.filtro.farolAtrasos.filter(itemFiltro => 
						itemFiltro == 'ADIANTADO' && item.status == 1
						|| itemFiltro == 'ATRASADO' && (item.status == 2 || item.status == 3)
						|| itemFiltro == 'NENHUM' && !item.status
					).length) {
					return false;
				}
				if ((this.state.filtro.fronteiras || []).length && !this.state.filtro.fronteiras.filter(itemFiltro => itemFiltro == item.fronteira).length) {
					return false;
				}
				
				if (filtroOperacoes.length 
					&& !filtroOperacoes.filter(operacao => (item.descricao_operacao || '').split(', ').includes(operacao.nome)).length) {
					return false;
				}
				if ((this.state.filtro.negociadores || []).length && !this.state.filtro.negociadores.filter(itemFiltro => itemFiltro == item.id_negociador).length) {
					return false;
				}

				return true;
			})

			//FILTROS SWITCH
			.filter(item => {
				
				if (this.state.filtro.lote && item.lotes <= 0) {
					return false;
				}
				if (this.state.filtro.classificada && !item.classificada) {
					return false;
				}
				if (this.state.filtro.expressa && !item.expressa) {
					return false;
				}
				if (this.state.filtro.ocorrenciaAberta && !item.ocorrencias_abertas) {
					return false;
				}
				if (this.state.filtro.cavaloEngatado && !item.cavalo) {
					return false;
				}
				if (this.state.filtro.somenteCarreta && (!item.carreta || item.cavalo)) {
					return false;
				}
				if (this.state.filtro.semConjunto && (item.cavalo || item.carreta)) {
					return false;
				}
				if (this.state.filtro.emCD && !item.em_cd) {
					return false;
				}
				if (this.state.filtro.semDocumento && item.qtd_documentos > 0) {
					return false;
				}
				if (!this.state.filtro.nacional || !this.state.filtro.internacional) {
					//Se não tem rumo significa que ainda não foram registrados eventos no shipping
					if (!this.state.filtro.nacional && !this.state.filtro.internacional) {
						return item.rumo == 'INDEFINIDO';
					}
					if (this.state.filtro.nacional && item.rumo != 'NAC') {
						return false;
					}
					if (this.state.filtro.internacional && item.rumo == 'NAC') {
						return false;
					}
				}
				
				return true;

			})
			//FILTRO BUSCA TEXTO
			.filter(item => {

				const colunasPesquisa = this.state.filtro.colunasPesquisa;

				return !(this.state.pesquisar || '').trim()
					|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {
					if (pesquisar
						&& !(`
							${!colunasPesquisa.length ? item.shipping_code_id : ''}
							${!colunasPesquisa.length ? (item.documentos || []).filter(documento => documento).join(', ') : ''}
							${!colunasPesquisa.length ? item.negociador : ''}
							${!colunasPesquisa.length ? `${item.cidade_origem_shipping} ${item.cidade_origem_shipping ? '-' : ''} ${item.cidade_destino_shipping}` : ''}
							${!colunasPesquisa.length ? item.fronteira : ''}
							${!colunasPesquisa.length ? item.cavalo : ''}
							${!colunasPesquisa.length ? item.carreta : ''}
							${!colunasPesquisa.length ? item.veiculo_referencia : ''}
							${!colunasPesquisa.length ? item.documento : ''}

							${((item.id_status == 1/* && item.cavalo*/) || item.id_status == 3 || item.id_status == 5) ? //Programação, Primeiro Trânsito e Segundo Trânsito
								`${item.nome_proxima_previsao} ${DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao)}` : ''}
							
							${item.nome_status} 
							${(item.id_status == 2 || item.id_status == 4 || item.id_status == 6) ? //Carregamento, Fronteira e Descarga
								`${item.previsao_diferenca_dias > 0 ? `${item.previsao_diferenca_dias}d` : ''} ${item.previsao_diferenca_horas > 0 ? `${item.previsao_diferenca_horas}h` : ''} ${item.previsao_diferenca_minutos > 0 ? `${item.previsao_diferenca_minutos} min` : ''}` : ''};
								
							${((item.id_status == 1 && item.cavalo) || item.id_status == 3 || item.id_status == 5) ? //Programação, Primeiro Trânsito e Segundo Trânsito
								`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao_real)}` : ''}

							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Sol Carga').length ? item.filtro_solicitacao_carga_id : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Últi Rom').length ? item.filtro_romaneio_atual : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Documento').length ? item.filtro_documento : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Referência').length ? item.filtro_referencia_carga : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Fat. Comercial / NF').length ? item.filtro_fatura : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Motorista').length ? item.filtro_motorista : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Aduanas').length ? item.filtro_aduana : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Remetente').length ? item.filtro_nome_remetente_sol : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Destinatário').length ? item.filtro_nome_destinatario_sol : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Cidade Origem').length ? item.filtro_municipio_origem_sol : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Cidade Destino').length ? item.filtro_municipio_destino_sol : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'País Origem').length ? item.filtro_pais_origem_shipping : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'País Destino').length ? item.filtro_pais_destino_shipping : ''}
							${colunasPesquisa.length && colunasPesquisa.filter(item => item == 'Tipo de Carroceria').length ? item.filtro_tipo_carreta : ''}							
						`).toUpperCase().includes(pesquisar.trim())) {
						return false;
					}

					return true;
				}).length > 0;

			});

			if (this.state.ordenacao.shipping) {
				lista.sort((a, b) => this.state.ordenacao.shipping == 1 
					? (parseInt(a.shipping_code_id) > parseInt(b.shipping_code_id) ? 1 : -1) 
					: (parseInt(a.shipping_code_id) > parseInt(b.shipping_code_id) ? -1 : 1));
			} else if (this.state.ordenacao.documentos) {
				lista.sort((a, b) => this.state.ordenacao.documentos == 1 
					? ((a.documentos || []).filter(documento => documento).join(', ') > (b.documentos || []).filter(documento => documento).join(', ') ? 1 : -1) 
					: ((a.documentos || []).filter(documento => documento).join(', ') > (b.documentos || []).filter(documento => documento).join(', ') ? -1 : 1));
			} else if (this.state.ordenacao.cavalo) {
				lista.sort((a, b) => this.state.ordenacao.cavalo == 1 
					? ((a.cavalo || '') > (b.cavalo || '') ? 1 : -1) 
					: ((a.cavalo || '') > (b.cavalo || '') ? -1 : 1));
			} else if (this.state.ordenacao.carreta) {
				lista.sort((a, b) => this.state.ordenacao.carreta == 1 
					? ((a.carreta || '') > (b.carreta || '') ? 1 : -1) 
					: ((a.carreta || '') > (b.carreta || '') ? -1 : 1));
			} else if (this.state.ordenacao.lote) {
				lista.sort((a, b) => this.state.ordenacao.lote == 1 
					? (a.lotes > b.lotes ? 1 : -1) 
					: (a.lotes > b.lotes ? -1 : 1));
			} else if (this.state.ordenacao.classificada) {
				lista.sort((a, b) => this.state.ordenacao.classificada == 1 
					? (a.classificada > b.classificada ? 1 : -1) 
					: (a.classificada > b.classificada ? -1 : 1));
			} else if (this.state.ordenacao.expressa) {
				lista.sort((a, b) => this.state.ordenacao.expressa == 1 
					? (a.expressa > b.expressa ? 1 : -1) 
					: (a.expressa > b.expressa ? -1 : 1));
			} else if (this.state.ordenacao.ocorrencia) {
				lista.sort((a, b) => this.state.ordenacao.ocorrencia == 1 
					? (a.ocorrencias_abertas > b.ocorrencias_abertas ? 1 : -1) 
					: (a.ocorrencias_abertas > b.ocorrencias_abertas ? -1 : 1));
			} else if (this.state.ordenacao.cliente) {
				lista.sort((a, b) => this.state.ordenacao.cliente == 1 
					? (a.negociador > b.negociador ? 1 : -1) 
					: (a.negociador > b.negociador ? -1 : 1));
			} else if (this.state.ordenacao.rota) {
				lista.sort((a, b) => this.state.ordenacao.rota == 1 
					? (`${a.cidade_origem_shipping} ${a.cidade_origem_shipping ? '-' : ''} ${a.cidade_destino_shipping}` > `${b.cidade_origem_shipping} ${b.cidade_origem_shipping ? '-' : ''} ${b.cidade_destino_shipping}` ? 1 : -1) 
					: (`${a.cidade_origem_shipping} ${a.cidade_origem_shipping ? '-' : ''} ${a.cidade_destino_shipping}` > `${b.cidade_origem_shipping} ${b.cidade_origem_shipping ? '-' : ''} ${b.cidade_destino_shipping}` ? -1 : 1));
			} else if (this.state.ordenacao.fronteira) {
				lista.sort((a, b) => this.state.ordenacao.fronteira == 1 
					? (a.fronteira > b.fronteira ? 1 : -1) 
					: (a.fronteira > b.fronteira ? -1 : 1));
			} else if (this.state.ordenacao.previsao) {
				lista.sort((a, b) => {

					let previsaoA = (a.id_status == 1/* && a.cavalo*/) || a.id_status == 3 || a.id_status == 5 
						? `${a.id_proxima_previsao}-${a.datahora_previsao}` : '0';

					let previsaoB = (b.id_status == 1/* && b.cavalo*/) || b.id_status == 3 || b.id_status == 5 
						? `${b.id_proxima_previsao}-${b.datahora_previsao}` : '0';
					
					return this.state.ordenacao.previsao == 1 
						? (previsaoA > previsaoB ? 1 : -1) 
						: (previsaoA > previsaoB ? -1 : 1)
				});
			} else if (this.state.ordenacao.statusPrevisao) {
				
				lista.sort((a, b) => {
					let tempoA = a.id_status == 1 || a.id_status == 2 || a.id_status == 4 || a.id_status == 6 ? (
						((parseInt(a.previsao_diferenca_dias || 0) * 24) * 60) 
						+ (parseInt(a.previsao_diferenca_horas || 0) * 60) 
						+ parseInt(a.previsao_diferenca_minutos || 0)
					) : 0;

					let tempoB = b.id_status == 1 || b.id_status == 2 || b.id_status == 4 || b.id_status == 6 ? (
						((parseInt(b.previsao_diferenca_dias || 0) * 24) * 60) 
						+ (parseInt(b.previsao_diferenca_horas || 0) * 60) 
						+ parseInt(b.previsao_diferenca_minutos || 0)
					) : 0;

					if (this.state.ordenacao.statusPrevisao == 1) {
						return (tempoA > tempoB ? 1 : -1);
					} else {
						return (tempoA > tempoB ? -1 : 1);
					}
				});
			} else if (this.state.ordenacao.posicao) {
				lista.sort((a, b) => this.state.ordenacao.posicao == 1 
					? (a.veiculo_referencia > b.veiculo_referencia ? 1 : -1) 
					: (a.veiculo_referencia > b.veiculo_referencia ? -1 : 1));
			} else if (this.state.ordenacao.statusPosicao) {
				lista.sort((a, b) => this.state.ordenacao.statusPosicao == 1 
				? (a.status > b.status ? 1 : -1) 
				: (a.status > b.status ? -1 : 1));
			} else if (this.state.ordenacao.destinado) {

				lista.sort((a, b) => {

					let destinadoA = 1;
					let destinadoB = 1;
					if (a.id_status == 1) {
						destinadoA = a.statusShipping.datahora_destinado_programacao ? 1 : 3;
					} else if (a.id_status == 3) {
						destinadoA = a.statusShipping.datahora_destinado_primeiro_transito ? 1 : 3;
					} else if (a.id_status == 5) {
						destinadoA = a.statusShipping.datahora_destinado_segundo_transito ? 1 : 3;
					}
					if (b.id_status == 1) {
						destinadoB = b.statusShipping.datahora_destinado_programacao ? 1 : 3;
					} else if (b.id_status == 3) {
						destinadoB = b.statusShipping.datahora_destinado_primeiro_transito ? 1 : 3;
					} else if (b.id_status == 5) {
						destinadoB = b.statusShipping.datahora_destinado_segundo_transito ? 1 : 3;
					}
				
					return this.state.ordenacao.destinado == 1 
						? (destinadoA > destinadoB ? 1 : -1) 
						: (destinadoA > destinadoB ? -1 : 1);
				});
			}

			return lista;
	}

	getNovaOrdenacao(atual) {
		return atual == 2 ? 0 : (atual || 0) + 1;
	}

	lista() {

		let listaInternacional = this.props.lista
			.filter(item => item.rumo != 'NAC')
			.filter(item => {
				if (this.props.usuarioCarregado && (this.props.usuarioCarregado.placas_cco || []).length > 0) {
					return (this.props.usuarioCarregado.placas_cco || []).filter(placa => placa == item.cavalo).length > 0;
				} else {
					return this.props.usuarioCarregado ? true : false;
				}
			})

			.filter(item => {
				if (this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente) {
					return (this.props.usuarioCarregado.id_negociadores || []).filter(negociador => negociador == item.id_negociador).length > 0;
				} else {
					return true;
				}
		});
		if (this.props.lista.length && !listaInternacional.length && !this.state.filtro.apenasNacional) {
			if (this.state.filtro.internacional) {
				this.setState({ 
					...this.state, 
					filtro: { 
						...this.state.filtro, 
						internacional: false,
						apenasNacional: true
					}
				});
			}
		}

		let acessoCliente = this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente;

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						{/*<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
								}} />
							</Grid>*/}

					</Row>

					{this.state.registroModal ? this.modalShipping() : null}									

					<div style={{ display: 'flex'} }>
						<div style={{ width: '2%', minWidth: 30, display: 'flex', alignItems: 'center', paddingBottom: 20 }}>
							<SwitchVertical onChange={() => {
								this.setState({ ...this.state, ativado: !this.state.ativado, cardSelecionados: this.state.ativado ? [] : [ 1, 2, 3, 4, 5, 6] });						
							}} />
						</div>
						<div style={{ width: '98%' }}>
							<div class="row" style={{ justifyContent: 'center' }}>
								{[
									{ id: 1, nome: 'Programação' }, { id: 2, nome: 'Carregamento' }, { id: 3, nome: 'Primeiro Trânsito' }, 
									{ id: 4, nome: 'Fronteira' }, { id: 5, nome: 'Segundo Trânsito' }, { id: 6, nome: 'Descarga' }
								].filter(item => this.state.filtro.internacional || (item.id != 3 && item.id != 4)).map(item => {
									
									const backgroundColor = this.state.cardSelecionados.filter(status => status == item.id).length 
										? 'rgb(31, 48, 102, 0.9)' : '#a9b4d6';

									const color = this.state.cardSelecionados.filter(status => status == item.id).length 
										? '#fff' : 'rgb(31, 48, 102, 1)';
									

									let viagensTotal = this.filtrar(true).filter(shipping => shipping.id_status == item.id).length;
									let viagensAtrasadas = this.filtrar(true).filter(shipping => shipping.id_status == item.id && shipping.status > 2).length;
									let vigensOcorrencias = this.filtrar(true).filter(shipping => shipping.id_status == item.id && shipping.ocorrencias_abertas > 0).length;
									let percentual = viagensTotal && viagensAtrasadas ? 100 - ((viagensAtrasadas * 100) / viagensTotal) : 100;

									return (
										<div class="col-xs-6 col-sm-6 col-md-4 col-lg-2" 
											style={{ cursor: 'pointer' }}
											onClick={() => {
												let cardSelecionados = this.state.cardSelecionados.map(status => status);
												if (this.state.cardSelecionados.filter(status => status == item.id).length) {
													cardSelecionados = cardSelecionados.filter(status => status != item.id);
												} else {
													cardSelecionados.push(item.id);
												}
												this.setState({
													...this.state,
													cardSelecionados: cardSelecionados
												});

											}}>
											<div class="small-box" 
												style={{
													backgroundColor: backgroundColor
												}}>
												<div class="inner">
													<h5 style={{ textAlign: 'center', color: color, fontSize: 16 }}>{item.nome}</h5>
													<div class="progress-group">
														<div class="progress progress-sm">
															<div class={`progress-bar bg-${percentual > 80 ? 'success' : (percentual > 50 ? 'warning' : 'danger')}`} style={{ width: `${percentual}%` }}></div>
														</div>
													</div>
													<div style={{ display: 'flex', justifyContent: 'center' }}>
														<div style={{ fontWeight: 'bold', color: color }} title={'Total de shippings'}>
															<i class="fas fa-truck-moving" style={{ marginRight: 2 }}></i>{viagensTotal}
														</div>
														<div style={{ fontWeight: 'bold', marginLeft: 8, marginRight: 8, color: color }} title={'Shippings atrasados'}>
															<i class="fas fa-exclamation-circle" style={{ marginRight: 2 }}></i>{viagensAtrasadas}
														</div>
														<div style={{ fontWeight: 'bold', color: color }} title={'Shippings com ocorrência'}>
															<i class="fas fa-bell" style={{ marginRight: 2 }}></i>{vigensOcorrencias}
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					<Row alignCenter>
						{!this.state.filtro.apenasNacional &&
						<SelectNovo
							label='Rumo'
							multi
							options={this.props.listaRumos}
							cols='12 6 2 2'
							placeholder='Selecione'
							multiple={true}
							value={this.state.filtro.rumos}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										rumos: value.map(item => item.value)
									}
								});
							}} />}

						<SelectNovo
							label='Farol Atraso'
							multi
							options={this.props.listaFarolAtraso}
							cols='12 6 2 2'
							placeholder='Selecione'
							multiple={true}
							value={this.state.filtro.farolAtrasos}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										farolAtrasos: value.map(item => item.value)
									}
								});
							}} />
							
						{!this.state.filtro.apenasNacional &&
						<SelectDialog
							label='Fronteira'
							multi
							options={this.props.listaFronteiras.map(item => ({ id: item.fronteira, valor: item.fronteira }))}
							cols='12 6 3 3'
							placeholder='Selecione'
							multiple={true}
							value={this.state.filtro.fronteiras}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										fronteiras: value
									}
								});
							}} />}

						{!acessoCliente &&
						<SelectDialog
							label='Operação'
							multi
							options={this.props.listaOperacoes}
							cols='12 6 2 2'
							placeholder='Selecione'
							multiple={true}
							value={this.state.filtro.operacoes}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										operacoes: value
									}
								});
							}} />}

						{!acessoCliente &&
						<SelectDialog
							label='Negociador'
							multi
							options={this.props.listaNegociadores.map(item => ({ id: item.id_negociador, valor: item.negociador }))}
							cols='12 6 3 3'
							placeholder='Selecione'
							multiple={true}
							value={this.state.filtro.negociadores}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										negociadores: value
									}
								});
							}} />}
					</Row>

					<div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Lote'>
							<i class='fas fa-boxes' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch1'
									checked={this.state.filtro.lote}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, lote: !this.state.filtro.lote }})} />
								<label class="custom-control-label" for="customSwitch1"></label>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Classificada'>
							<i class='fas fa-exclamation-triangle' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch2'
									checked={this.state.filtro.classificada}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, classificada: !this.state.filtro.classificada }})} />
								<label class="custom-control-label" for="customSwitch2"></label>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Viagem Expressa'>
							<i class='fas fa-shipping-fast' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch3'
									checked={this.state.filtro.expressa}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, expressa: !this.state.filtro.expressa }})} />
								<label class="custom-control-label" for="customSwitch3"></label>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Ocorrência Aberta'>
							<i class='fas fa-bell' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch4'
									checked={this.state.filtro.ocorrenciaAberta}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, ocorrenciaAberta: !this.state.filtro.ocorrenciaAberta }})} />
								<label class="custom-control-label" for="customSwitch4"></label>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Cavalo engatado'>
							<i class='fas fa-link' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch5'
									checked={this.state.filtro.cavaloEngatado}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, cavaloEngatado: !this.state.filtro.cavaloEngatado }})} />
								<label class="custom-control-label" for="customSwitch5"></label>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Somente Carreta'>
							<i class='fas fa-truck-loading' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch6'
									checked={this.state.filtro.somenteCarreta}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, somenteCarreta: !this.state.filtro.somenteCarreta }})} />
								<label class="custom-control-label" for="customSwitch6"></label>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Sem conjunto'>
							<i class='fas fa-unlink' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch7'
									checked={this.state.filtro.semConjunto}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, semConjunto: !this.state.filtro.semConjunto }})} />
								<label class="custom-control-label" for="customSwitch7"></label>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Em CD'>
							<i class='fas fa-warehouse' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch8'
									checked={this.state.filtro.emCD}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, emCD: !this.state.filtro.emCD }})} />
								<label class="custom-control-label" for="customSwitch8"></label>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8  }} title='Sem Documento'>
							<i class='fas fa-file' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch9'
									checked={this.state.filtro.semDocumento}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, semDocumento: !this.state.filtro.semDocumento }})} />
								<label class="custom-control-label" for="customSwitch9"></label>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8  }} title='Nacionais'>
							<i class='fas fa-home' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch10'
									checked={this.state.filtro.nacional}
									disabled={!this.props.usuarioCarregado || !this.props.usuarioCarregado.nacional}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, nacional: !this.state.filtro.nacional }})} />
								<label class="custom-control-label" for="customSwitch10"></label>
							</div>
						</div>
						{!this.state.filtro.apenasNacional &&
						<div style={{ display: 'flex', alignItems: 'center' }} title='Internacionais'>
							<i class='fas fa-globe-americas' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch11'
									checked={this.state.filtro.internacional}
									disabled={!this.props.usuarioCarregado || !this.props.usuarioCarregado.internacional}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, internacional: !this.state.filtro.internacional }})} />
								<label class="custom-control-label" for="customSwitch11"></label>
							</div>
						</div>}
					</div>

				</ContentCardHeader>
				<ContentCardBody>

					
					{/*
					FILTRO GERAL
					*/}
					
					<Row>
						<Grid cols='12 6 8 8'>
							<div class='input-group' id='pesquisar'>
								<input 
									type='text'
									class='form-control datetimepicker-input'
									data-target='#pesquisar'
									placeholder='Pesquisar (adicione + para combinar pesquisas)'
									value={this.state.pesquisarAux}
									onChange={e => {
										this.setState({ ...this.state, pesquisarAux: e.target.value });
									}}
									onKeyDown={e => {
										if (e.key === 'Enter') {
											this.setState({ ...this.state, pesquisar: this.state.pesquisarAux });
										}
									}} />
								<div type='button' class='input-group-append' data-target='#pesquisar'>
									<div 
										class='input-group-text'
										onClick={e => {
											this.setState({ ...this.state, pesquisar: this.state.pesquisarAux });
										}}>
										<i class='fas fa-search'></i></div>
								</div>
							</div>
						</Grid>
						<Select
							multi
							options={[
								{ id: 'Aduanas', valor: 'Aduanas' },
								{ id: 'Destinatário', valor: 'Destinatário' },
								{ id: 'Documento', valor: 'Documento' },
								{ id: 'Referência', valor: 'Referência' },
								{ id: 'Fat. Comercial / NF', valor: 'Fat. Comercial / NF' },
								{ id: 'Motorista', valor: 'Motorista' },
								{ id: 'Remetente', valor: 'Remetente' },
								{ id: 'Sol Carga', valor: 'Sol Carga' },
								{ id: 'Últi Rom', valor: 'Últi Rom' },
								{ id: 'Cidade Origem', valor: 'Cidade Origem' },
								{ id: 'País Origem', valor: 'País Origem' },
								{ id: 'Cidade Destino', valor: 'Cidade Destino' },
								{ id: 'País Destino', valor: 'País Destino' },
								{ id: 'Tipo de Carroceria', valor: 'Tipo de Carroceria' }
							]}
							cols='12 6 4 4'
							placeholder='Selecione as colunas'
							multiple={true}
							value={this.state.filtro.colunasPesquisa}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										colunasPesquisa: value.map(item => item.value)
									}
								});
							}} />
					</Row>
					<Table responsive border alturaFixa maxHeight={window.innerWidth < 768 ? '100%' : (window.innerWidth < 1430 ? '75vh' : `${(window.innerHeight - 300) * 0.0926}vh`)}>
						<THead>
							<Tr backgroundColor={'#fff'}>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { shipping: this.getNovaOrdenacao(this.state.ordenacao.shipping) }})} sticky width={10} color={'#1F3066'} alignCenter>Shipping {this.state.ordenacao.shipping == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.shipping == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { documentos: this.getNovaOrdenacao(this.state.ordenacao.documentos) }})} sticky width={10} color={'#1F3066'} alignCenter>Documentos {this.state.ordenacao.documentos == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.documentos == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { cavalo: this.getNovaOrdenacao(this.state.ordenacao.cavalo) }})} sticky width={10} color={'#1F3066'} alignCenter>Cavalo {this.state.ordenacao.cavalo == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.cavalo == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { carreta: this.getNovaOrdenacao(this.state.ordenacao.carreta) }})} sticky width={10} color={'#1F3066'} alignCenter>Carreta {this.state.ordenacao.carreta == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.carreta == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { lote: this.getNovaOrdenacao(this.state.ordenacao.lote) }})} sticky width={10} color={'#1F3066'} alignCenter><i class='fas fa-boxes' title='Lote'></i> {this.state.ordenacao.lote == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.lote == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { classificada: this.getNovaOrdenacao(this.state.ordenacao.classificada) }})} sticky width={10} color={'#1F3066'} alignCenter><i class='fas fa-exclamation-triangle' title='Classificada'></i> {this.state.ordenacao.classificada == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.classificada == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { expressa: this.getNovaOrdenacao(this.state.ordenacao.expressa) }})} sticky width={10} color={'#1F3066'} alignCenter><i class='fas fa-shipping-fast' title='Viagem Expressa'></i> {this.state.ordenacao.expressa == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.expressa == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { ocorrencia: this.getNovaOrdenacao(this.state.ordenacao.ocorrencia) }})} sticky width={10} color={'#1F3066'} alignCenter><i class='fas fa-bell' title='Ocorrência'></i> {this.state.ordenacao.ocorrencia == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.ocorrencia == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { cliente: this.getNovaOrdenacao(this.state.ordenacao.cliente) }})} sticky width={150} color={'#1F3066'} alignCenter>Cliente {this.state.ordenacao.cliente == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.cliente == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { rota: this.getNovaOrdenacao(this.state.ordenacao.rota) }})} sticky width={150} color={'#1F3066'} alignCenter>Rota {this.state.ordenacao.rota == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.rota == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { fronteira: this.getNovaOrdenacao(this.state.ordenacao.fronteira) }})} sticky width={150} color={'#1F3066'} alignCenter>Fronteira {this.state.ordenacao.fronteira == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.fronteira == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>}
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { previsao: this.getNovaOrdenacao(this.state.ordenacao.previsao) }})} sticky width={100} color={'#1F3066'} alignCenter>Previsão {this.state.ordenacao.previsao == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.previsao == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { statusPrevisao: this.getNovaOrdenacao(this.state.ordenacao.statusPrevisao) }})} sticky width={100} color={'#1F3066'} alignCenter>Status {this.state.ordenacao.statusPrevisao == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.statusPrevisao == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { posicao: this.getNovaOrdenacao(this.state.ordenacao.posicao) }})} sticky width={100} color={'#1F3066'} alignCenter>Posição {this.state.ordenacao.posicao == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.posicao == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { statusPosicao: this.getNovaOrdenacao(this.state.ordenacao.statusPosicao) }})} sticky width={10} color={'#1F3066'} alignCenter>Status {this.state.ordenacao.statusPosicao == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.statusPosicao == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								{!acessoCliente &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { destinado: this.getNovaOrdenacao(this.state.ordenacao.destinado) }})} sticky width={10} color={'#1F3066'} alignCenter>Dest. {this.state.ordenacao.destinado == 1 ? <i class="fas fa-sort-amount-down-alt" title='Destinado' /> : (this.state.ordenacao.destinado == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>}
								<Th sticky width={80} color={'#1F3066'} alignCenter>
									<ButtonTable
										type={'light'}
										icon={this.state.resumido ? 'fas fa-expand' : 'fas fa-compress'}
										visible={true}
										title='Mostrar as informações resumidas ou quebrar a linha'
										small
										event={() => {
											this.setState({
												...this.state,
												resumido: !this.state.resumido
											});
										}} />
								</Th>
							</Tr>
						</THead>
						<TBody>
							{this.filtrar().map(item => {

								let listaSolicitacoes = item.shipping_code_id == this.state.idShippingExpandido
									? this.props.listaSolicitacoes.filter(solicitacao => solicitacao.shipping_code_id == this.state.idShippingExpandido)
									: [];

								return (
									<>
										<Tr key={item.shipping_code_id} backgroundColor={'#ffffff'}>
											<Td minWidth={10}  color={'#000'} alignCenter small title={item.shipping_code_id}>{this.isFavorito(item.shipping_code_id) && <i class={'fas fa-star'} />} {item.shipping_code_id}</Td>
											<Td minWidth={10}  color={'#000'} alignCenter small>{(item.documentos || []).filter(documento => documento).join(', ')}</Td>
											<Td minWidth={10}  color={'#000'} alignCenter small>{item.cavalo}</Td>
											<Td minWidth={10}  color={'#000'} alignCenter small>{item.carreta}</Td>
											<Td minWidth={10}  color={'#1F3066'} alignCenter small>
												{item.lotes > 1 &&
												<span class='float-right badge bg-secondary' title='Lote' style={{ fontSize: 11 }}>{item.lotes}</span>}
											</Td>
											<Td minWidth={10}  color={'#000'} alignCenter small>
												{item.classificada &&
												<i class='fas fa-exclamation-triangle' title='Classificada'></i>}
											</Td>
											<Td minWidth={10}  color={'#f00'} alignCenter small>
												{item.expressa &&
												<i class='fas fa-shipping-fast' title='Viagem Expressa'></i>}
											</Td>
											<Td minWidth={10}  color={item.ocorrencias_abertas > 0 ? '#f00' : '#000'} alignCenter small>
												{item.ocorrencias_abertas > 0 ? (
													<i class='fas fa-bell' title='Ocorrência aberta'></i>
												) : (item.ocorrencias_finalizadas ? (
													<i class='fas fa-bell' title='Ocorrência Finalizada'></i>
												) : null)}
											</Td>
											<Td minWidth={150} color={'#000'} alignCenter small>{item.negociador}</Td>
											<Td minWidth={150} color={'#000'} alignCenter small noWrap={this.state.resumido} title={`${item.cidade_origem_shipping} ${item.cidade_origem_shipping ? '-' : ''} ${item.cidade_destino_shipping}`}>{item.cidade_origem_shipping} {item.cidade_origem_shipping ? '-' : ''} {item.cidade_destino_shipping}</Td>
											{this.state.filtro.internacional &&
											<Td minWidth={150} color={'#000'} alignCenter small noWrap={this.state.resumido}>{item.fronteira}</Td>}
											<Td minWidth={100}  color={'#000'} alignCenter small>
												{((item.id_status == 1/* && item.cavalo*/) || item.id_status == 3 || item.id_status == 5) ? //Programação, Primeiro Trânsito e Segundo Trânsito
													`${item.nome_proxima_previsao} ${DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao)}`
												: ''}
											</Td>
											<Td minWidth={100}  color={'#000'} alignCenter small
												title={
													`${item.nome_status}`
													+ `\n${(item.id_status == 2 || item.id_status == 4 || item.id_status == 6) ? //Carregamento, Fronteira e Descarga
														`${item.previsao_diferenca_dias > 0 ? `${item.previsao_diferenca_dias}d` : ''} ${item.previsao_diferenca_horas > 0 ? `${item.previsao_diferenca_horas}h` : ''} ${item.previsao_diferenca_minutos > 0 ? `${item.previsao_diferenca_minutos} min` : ''}` 
													: ''}`
													+ `\n${((item.id_status == 1 && item.cavalo) || item.id_status == 3 || item.id_status == 5) ? //Programação, Primeiro Trânsito e Segundo Trânsito
														`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao_real)}`
													: ''}`
												}>
												{item.nome_status} 
												{(item.id_status == 2 || item.id_status == 4 || item.id_status == 6) && //Carregamento, Fronteira e Descarga
												<div>
													{item.previsao_diferenca_dias > 0 ? `${item.previsao_diferenca_dias}d` : ''} {item.previsao_diferenca_horas > 0 ? `${item.previsao_diferenca_horas}h` : ''} {item.previsao_diferenca_minutos > 0 ? `${item.previsao_diferenca_minutos} min` : ''}
												</div>}
												{((item.id_status == 1 && item.cavalo) || item.id_status == 3 || item.id_status == 5) && //Programação, Primeiro Trânsito e Segundo Trânsito
												<div>
													{DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao_real)}
												</div>}
											</Td>
											<Td minWidth={100} color={'#000'} alignCenter small noWrap={this.state.resumido}>{item.veiculo_referencia}</Td>
											<Td minWidth={10}  color={item.status == 3 ? '#ff0000' : (item.status == 2 ? '#eeff00' : (item.status == 1 ? '#00cf1f' : '#bfbfbf'))} title={'Status'} alignCenter small>
												<i class='fas fa-circle' style={{ fontSize: 18 }}></i>
											</Td>
											{!acessoCliente &&
											<Td minWidth={10}  color={'#000'} alignCenter>
												{item.id_status == 1 &&
												<ButtonTable
													width={26}
													height={26}
													type={'success'}
													icon={item.statusShipping && item.statusShipping.datahora_destinado_programacao 
														? 'fas fa-check' : ''}
													visible={true}
													small
													title={item.statusShipping && item.statusShipping.datahora_destinado_programacao 
														? `Destinado - ${DateFormat.formatarDataHoraSqlParaTela(item.statusShipping.datahora_destinado_programacao)}` : 'Não Destinado'}
													event={() => {
														this.props.setDestinado('PROGRAMACAO', item);

													}} />}
												
												{item.id_status == 3 &&
												<ButtonTable
													width={24}
													height={24}
													type={'success'}
													icon={item.statusShipping && item.statusShipping.datahora_destinado_primeiro_transito 
														? 'fas fa-check' : ''}
													visible={true}
													small
													title={item.statusShipping && item.statusShipping.datahora_destinado_primeiro_transito 
														? `Destinado - ${DateFormat.formatarDataHoraSqlParaTela(item.statusShipping.datahora_destinado_primeiro_transito)}` : 'Não Destinado'}
													event={() => {
														this.props.setDestinado('PRIMEIRO_TRANSITO', item);

													}} />}

												{item.id_status == 5 &&
												<ButtonTable
													width={24}
													height={24}
													type={'success'}
													icon={item.statusShipping && item.statusShipping.datahora_destinado_segundo_transito 
														? 'fas fa-check' : ''}
													visible={true}
													small
													title={item.statusShipping && item.statusShipping.datahora_destinado_segundo_transito 
														? `Destinado - ${DateFormat.formatarDataHoraSqlParaTela(item.statusShipping.datahora_destinado_segundo_transito)}` : 'Não Destinado'}
													event={() => {
														this.props.setDestinado('SEGUNDO_TRANSITO', item);

													}} />}
											</Td>}
											<Td minWidth={80}  color={'#000'} alignCenter>
												<ButtonTable
													type={'primary'}
													icon={'fas fa-eye'}
													visible={true}
													small
													title={'Ver detalhes'}
													event={() => {
														this.setState({
															...this.state,
															registroModal: { ...item }
														});
														
														this.props.getListaSolicitacoes(item.shipping_code_id);
														this.props.getListaOcorrencias(item.shipping_code_id);

													}} />
												<ButtonTable
													type={'primary'}
													icon={this.state.idShippingExpandido == item.shipping_code_id ? 'fas fa-minus' : 'fas fa-plus'}
													visible={true}
													small
													title={'Ver solicitações'}
													event={() => {
														if (this.state.idShippingExpandido != item.shipping_code_id) {
															this.props.getListaSolicitacoes(item.shipping_code_id);
														}
														this.setState({
															...this.state,
															idShippingExpandido: this.state.idShippingExpandido == item.shipping_code_id ? null : item.shipping_code_id
														});
													}} />
											</Td>
										</Tr>
										{listaSolicitacoes.length > 0 && 
										<Tr>
											<Td colspan={this.state.filtro.internacional ? 17 : 16} color={'#01071a'} small>												
												{this.props.listaSolicitacoes.filter((solicitacao, i) => solicitacao.shipping_code_id == this.state.idShippingExpandido)
												.map((solicitacao, i) => {
													
													// let operacao = this.props.listaOperacoes.filter(opItem => (solicitacao.descricao_operacao || '').split(', ').includes(opItem.valor))[0];
													let operacao = this.props.listaOperacoes.filter(opItem => String(solicitacao.operacao_id || '').split(', ').includes(opItem.codigo))[0];
													let documentosComprovantes = operacao ? (operacao.documentos || []).map(doc => doc.nome_documento).join(', ') : '';
													let documentosExcecoes = operacao ? (operacao.excecoes || []).filter(doc => !doc.cnpj_destinatario || FormatUtils.removerMascara(doc.cnpj_destinatario) == solicitacao.codigo_destinatario).map(doc => doc.excecao).join(', ') : '';
													
													return (
														<Row key={solicitacao.solicitacao_carga_id} style={{ borderTop: i == 0 ? null : '2px solid #1F3066' }}>
															<Grid cols='12 6 3 3' style={{ backgroundColor: '#dae0f5', paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4 }}>
																<p style={{ margin: 0 }}>Sol Carga: {solicitacao.solicitacao_carga_id}</p>
																<p style={{ margin: 0 }}>Últi Rom: {solicitacao.romaneio_atual}</p>
																<p style={{ margin: 0 }}>Documento: {solicitacao.documento}</p>
																<p style={{ margin: 0 }}>Referência: {solicitacao.observacao}</p>
															</Grid>
															<Grid cols='12 6 3 3' style={{ backgroundColor: '#e6eafa', paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4 }}>												
																<p style={{ margin: 0 }}>Fat. Comercial / NF: {solicitacao.fatura}</p>			
																<p style={{ margin: 0 }}>Peso / Quantidade: {FormatUtils.formatarValorTelaDecimal(solicitacao.documento_peso, 2)} / {FormatUtils.formatarValorTelaDecimal(solicitacao.documento_volumes, 2)}</p>
																<p style={{ margin: 0 }}>Motorista: {solicitacao.motorista}</p>
																<p style={{ margin: 0 }}>Aduanas: {solicitacao.aduana}</p>															
															</Grid>
															<Grid  cols='12 6 3 3' style={{ backgroundColor: '#dae0f5', paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4 }}>															
																<p style={{ margin: 0 }}>Remetente: {solicitacao.nome_remetente_sol}</p>
																<p style={{ margin: 0 }}>Origem: {solicitacao.municipio_origem_sol}</p>
																<p style={{ margin: 0 }}>Destino: {solicitacao.municipio_destino_sol}</p>
																<p style={{ margin: 0 }}>Previsão de Coleta: {DateFormat.formatarDataHoraSqlParaTela(solicitacao.data_previsao_carga)}</p>
															</Grid>
															<Grid  cols='12 6 3 3' style={{ backgroundColor: '#e6eafa', paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4 }}>
																<p style={{ margin: 0 }}>Destinatário: {solicitacao.nome_destinatario_sol}</p>
																{((solicitacao.id_fronteira_ref && solicitacao.data_liberacao_fronteira) || (!solicitacao.id_fronteira_ref && solicitacao.data_termino_carga)) &&
																<p style={{ margin: 0 }}>Previsão de Entrega: {DateFormat.formatarDataHoraSqlParaTela(solicitacao.data_previsao_descarga)}</p>}
																{(item.id_status == 1 || item.id_status == 3 || item.id_status == 5) &&
																<p style={{ margin: 0 }}>Previsão Real: {DateFormat.formatarDataHoraSqlParaTela(solicitacao.datahora_previsao_real)}</p>}
																<p style={{ margin: 0 }}>Status: <i class='fas fa-circle' style={{ fontSize: 16, color: item.status == 3 ? '#ff0000' : (item.status == 2 ? '#eeff00' : (item.status == 1 ? '#00cf1f' : '#bfbfbf')) }}></i></p>

																<p style={{ margin: 0 }}>Comp.: {documentosComprovantes || '-'}</p>
																<p style={{ margin: 0 }}>
																	Exceção: 
																	{documentosExcecoes &&
																	<i class='fas fa-exclamation-circle' 
																		style={{ color: '#c49b04', fontSize: 16, paddingTop: 4, paddingLeft: 4, paddingRight: 4 }} >
																	</i>}
																	{documentosExcecoes || '-'}
																</p>
															</Grid>
														</Row>
													);
												})}
											</Td>
										</Tr>}
									</>
								)
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	controleFavoritos(idShippingCode) {
		let lista = JSON.parse(Cookies.get('shippingsFavoritos') || '[]');

		if (this.isFavorito(idShippingCode)) {
			lista = lista.filter(item => item != idShippingCode);
		} else {
			lista.push(idShippingCode);
		}

		lista = lista.filter(item => (this.props.lista || []).filter(shipping => shipping.shipping_code_id == item).length);

		this.setState({ ...this.state, listaFavoritos: lista });

		Cookies.set('shippingsFavoritos', JSON.stringify(lista), { expires: 15 });
	}

	isFavorito(idShippingCode) {
		return (this.state.listaFavoritos || []).filter(shipping => shipping == idShippingCode).length > 0;
	}
	
	modalShipping() {

		const qtdSolicitacoes = this.props.listaSolicitacoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).length;
		let mostrarColunaSolicitacao = false;
		this.props.listaPrevisoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).map(item => {
			if ((item.solicitacoes || []).filter(sol => sol).length != qtdSolicitacoes) {
				mostrarColunaSolicitacao = true;
			}
		});

		let solicitacao = this.props.listaSolicitacoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id)[0] || {};

		let placas = [];
		this.props.listaSolicitacoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).forEach(item => {
			if (!placas.filter(placa => placa.cavalo == item.cavalo).length) {
				placas.push(item);
			}
		});

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>Shipping Code: {this.state.registroModal.shipping_code_id} <i class={this.isFavorito(this.state.registroModal.shipping_code_id) ? 'fas fa-star' : 'far fa-star'} title={this.isFavorito(this.state.registroModal.shipping_code_id) ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={() => this.controleFavoritos(this.state.registroModal.shipping_code_id)} style={{ marginLeft: 8, cursor: 'pointer' }}></i></h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, registroModal: null })}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ height: window.innerHeight * 0.8, overflowY: 'scroll' }}>

							{/*<h6>Previsões</h6>

							<Table responsive>
								<THead>
									<Tr>
										{mostrarColunaSolicitacao &&
										<Th width={100} alignCenter>Solicitações</Th>}
										<Th width={100} alignCenter>Nome</Th>
										<Th width={100} alignCenter>Data Hora</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaPrevisoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).map(item => (
										<Tr key={item.solicitacoes}>
											{mostrarColunaSolicitacao &&
											<Td alignCenter small>{(item.solicitacoes || []).filter(sol => sol).join(', ')}</Td>}
											<Td alignCenter small>{item.nome || ''}</Td>
											<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora)}</Td>
										</Tr>
									))}
								</TBody>
							</Table>

							<div style={{ width: '100%', height: 2, backgroundColor: '#1F3066', marginTop: 12, marginBottom: 12 }}></div>

							<h6>Eventos</h6>

							<Table responsive>
								<THead>
									<Tr>
										{mostrarColunaSolicitacao &&
										<Th width={100} alignCenter>Solicitações</Th>}
										<Th width={100} alignCenter>Nome</Th>
										<Th width={100} alignCenter>Data Hora</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaEventos.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).map(item => (
										<Tr key={item.solicitacoes}>
											{mostrarColunaSolicitacao &&
											<Td alignCenter small>{(item.solicitacoes || []).filter(sol => sol).join(', ')}</Td>}
											<Td alignCenter small>{item.nome || ''}</Td>
											<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora)}</Td>
										</Tr>
									))}
								</TBody>
							</Table>*/}

							<h6>Eventos</h6>

							<Table responsive>
								<THead>
									<Tr>
										<Th width={200} alignLeft>Evento</Th>
										<Th width={300} alignCenter>Referência</Th>
										<Th width={100} alignCenter>Previsão</Th>
										<Th width={100} alignCenter>Realizado</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaPrevisoesEventos.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id)
									.filter(item => {
										if (solicitacao.pais_origem_shipping == 'Brasil' && solicitacao.pais_destino_shipping == 'Brasil') {
											return item.nacional;
										} else {
											return true;
										}
									}).map(item => {
										return (
											<Tr key={item.solicitacoes}>
												<Td width={200} alignLeft small>{item.nome || ''}</Td>
												<Td width={300} alignCenter small>{item.referencia || ''}</Td>
												<Td width={100} alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao)}</Td>
												<Td width={100} alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_realizado)}</Td>
											</Tr>
										);
									})}
								</TBody>
							</Table>

							<div style={{ width: '100%', height: 2, backgroundColor: '#1F3066', marginTop: 12, marginBottom: 12 }}></div>
							
							<h6>Ocorrências</h6>

							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter>Tipo</Th>
										<Th alignCenter>Responsável</Th>
										<Th alignCenter>Início</Th>
										<Th alignCenter>Fim</Th>
										<Th alignCenter>Descrição</Th>
										<Th alignCenter>Usuário</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaOcorrencias.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).map(item => (
										<Tr key={item.id_ocorrencia}>
											<Td alignCenter small>{item.descricao_tipo_ocorrencia || ''}</Td>
											<Td alignCenter small>{item.responsabilidade == 0 ? 'Cliente' : 'Letsara'}</Td>
											<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.data_inicio)}</Td>
											<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.data_termino)}</Td>
											<Td alignCenter small>{item.comentario || ''}</Td>
											<Td alignCenter small>{item.usuario_inclusao || ''}</Td>
										</Tr>
									))}
								</TBody>
							</Table>

							<div style={{ width: '100%', height: 2, backgroundColor: '#1F3066', marginTop: 12, marginBottom: 12 }}></div>

							<h6>Placas</h6>

							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter>Cavalo</Th>
										<Th alignCenter>Carreta</Th>
										<Th alignCenter>Carroceria</Th>
										<Th alignCenter>Motorista</Th>
										<Th alignCenter>Local</Th>
										<Th alignCenter>Latitude/Longitude</Th>
										<Th alignCenter>Status</Th>
									</Tr>
								</THead>
								<TBody>
									{placas.map(item => (
										<Tr key={item.solicitacao_carga_id}>
											<Td alignCenter small>{item.cavalo || ''}</Td>
											<Td alignCenter small>{item.carreta || ''}</Td>
											<Td alignCenter small>{this.state.registroModal.tipo_carreta || ''}</Td>
											<Td alignCenter small>{item.motorista || ''}</Td>
											<Td alignCenter small>{item.veiculo_referencia || ''}</Td>
											<Td alignCenter small>
												{(item.veiculo_latitude && item.veiculo_longitude) &&
												<a target='blank' href={`https://www.google.com/maps/place/${item.veiculo_latitude},${item.veiculo_longitude}`}>
													{item.veiculo_latitude}, {item.veiculo_longitude}
												</a>}
											</Td>
											<Td alignCenter small>{item.veiculo_status || ''}</Td>
										</Tr>
									))}
								</TBody>
							</Table>

							<Grid cols="12 12 12 12">
								<div style={{ width: "100%", height: 500 }}>
									<MapLeaflet
										coordenadas={placas.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id && item.veiculo_latitude && item.veiculo_longitude)
											.map(item => ({
												id: item.solicitacao_carga_id,
												titulo: item.cavalo,
												latitude: item.veiculo_latitude,
												longitude: item.veiculo_longitude,
												direcao: this.state.registroModal.rumo == 'RS' ? 'S' : 'N'
											}))}
										zoom={4}
										latitude={'-28.381875'}
										longitude={'-53.937355'}
										onChange={(coordenadas) => {
										}}
									/>
								</div>
							</Grid>

						</div>
						{/*<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setState({ ...this.state, registroModal: null })}>Fechar</button>
						</div>*/}
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.reports.modoTela,
	aguardando: state.reports.aguardando,
	lista: state.reports.lista.map(item => {
		if (state.auth.usuario && state.auth.usuario.id == 214) { //Demonstração
			return {
				...item,
				negociador: 'DEMONSTRAÇÃO',
				nome_remetente_sol: 'DEMONSTRAÇÃO',
				nome_destinatario_sol: 'DEMONSTRAÇÃO',
				veiculo_referencia: 'DEMONSTRAÇÃO'
			}
		} else {
			return item;
		}
	}),
	listaFronteiras: state.reports.listaFronteiras,
	listaNegociadores: state.reports.listaNegociadores,
	listaRumos: state.reports.listaRumos,
	listaFarolAtraso: state.reports.listaFarolAtraso,
	listaOcorrencias: state.reports.listaOcorrencias,
	listaSolicitacoes: state.reports.listaSolicitacoes.map(item => {
		if (state.auth.usuario && state.auth.usuario.id == 214) { //Demonstração
			return {
				...item,
				negociador: 'DEMONSTRAÇÃO',
				nome_remetente_sol: 'DEMONSTRAÇÃO',
				nome_destinatario_sol: 'DEMONSTRAÇÃO',
				veiculo_referencia: 'DEMONSTRAÇÃO',
				observacao: 'DEMONSTRAÇÃO'
			}
		} else {
			return item;
		}
	}),
	listaPrevisoes: state.reports.listaPrevisoes,
	listaEventos: state.reports.listaEventos,
	listaPrevisoesEventos: state.reports.listaPrevisoesEventos.map(item => {
		if (state.auth.usuario && state.auth.usuario.id == 214) { //Demonstração
			return {
				...item,
				referencia: 'DEMONSTRAÇÃO',
				observacao: 'DEMONSTRAÇÃO'
			}
		} else {
			return item;
		}
	}),
	listaOperacoes: state.reports.listaOperacoes,
	filtro: state.reports.filtro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, setDestinado, getListaFronteiras, getListaNegociadores, getListaOperacoes, getLista, getListaSolicitacoes, getListaOcorrencias
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
