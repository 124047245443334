import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './ocorrenciaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';

import { 
	setModoTela, setFiltro, initForm, salvar, excluir, getLista, 
	getListaTipo, getListaMotorista, getListaGravidade, getListaLocal, 
	getListaResponsabilidade, getListaTipoCusto, salvarAbona, 
	getListaStatus, getListaAcao, getListaUsuario, getListaCategoria
} from './ocorrenciaActions';

class OcorrenciaDocumentoViagem extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		id_situacao: 1,
		id_interacao: 1
	}

    componentWillMount() {
        this.props.getLista(true);
		this.props.getListaTipo();
		this.props.getListaMotorista();
		this.props.getListaGravidade();
		this.props.getListaLocal();
		this.props.getListaResponsabilidade();
		this.props.getListaTipoCusto();
		this.props.getListaStatus();
		this.props.getListaAcao();
		this.props.getListaUsuario();
		this.props.getListaCategoria();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastroAvulsa' ? (
						<Form onSubmit={(registro) => this.props.salvar(registro)} />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form resumoDocumento onSubmit={(registro) => this.props.salvar(registro)} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form resumoDocumento excluir onSubmit={(registro) => this.props.excluir(registro)} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = this.props.lista.filter(item => {

			if (!item.id_documento_viagem 
				&& !item.id_vale_pedagio_entrada && !item.id_vale_pedagio_saida && !item.id_vale_pedagio_transferencia
				&& !item.avulsa) {
				return false;
			}
			if (this.state.id_status && this.state.id_status != item.id_status) {
				return false;
			}
			if (this.state.id_categoria && this.state.id_categoria != item.id_categoria) {
				return false;
			}
			if (this.state.id_tipo && this.state.id_tipo != item.id_tipo) {
				return false;
			}
			if (this.state.id_area && this.state.id_area != item.id_area) {
				return false;
			}
			if (this.state.id_motorista && this.state.id_motorista != item.id_motorista) {
				return false;
			}
			if ((this.state.id_situacao == 2 && item.datahora_concluido) || (this.state.id_situacao == 3 && !item.datahora_concluido)) {
				return false;
			}
			if ((this.state.tipo == 'DOC' && item.tipo != 'DOC') 
				|| (this.state.tipo == 'VPR' && item.tipo != 'VPR')
				|| (this.state.tipo == 'AVULSA' && item.tipo != 'AVULSA')) {
				return false;
			}

			if (!this.state.id_interacao || this.state.id_interacao == 1) { //Todos
				
			} else if (this.state.id_interacao == 2 && item.datahora_interacao) { //Sem Interação
				return false;
			} else if (this.state.id_interacao == 3 && !item.datahora_interacao) { //Com Interação
				return false;
			} else if (this.state.id_interacao == 4 && item.dias_interacao < 5) { //5 dias
				return false;
			} else if (this.state.id_interacao == 5 && item.dias_interacao < 10) { //10 dias
				return false;
			} else if (this.state.id_interacao == 6 && item.dias_interacao < 15) { //15 dias
				return false;
			}

			if (this.state.pesquisar
				&& !(`${item.id} ${item.tipo} ${item.nome_status || ''} ${item.nome_tipo || ''} 
				${DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia) || ''} 
				${DateFormat.formatarDataHoraSqlParaTela(item.datahora_concluido) || ''} 
				${DateFormat.formatarDataHoraSqlParaTela(item.datahora_interacao) || ''} 
				${item.tipo_documento || ''} ${item.numero_documento || ''} ${item.nome_acao || ''} 
				${DateFormat.formatarDataHoraSqlParaTela(item.datahora_emissao) || ''} 
				${DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrega) || ''}
				${item.nome_cliente || ''} ${item.nome_cliente || ''} ${item.nome_destinatario || ''} 
				${item.nome_ocorrencia_responsavel || ''} ${item.nome_responsavel || ''} 
				${item.nome_motorista || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}

			if (!this.props.usuarioCarregado) {
				return false;
			} else if (
				this.props.usuarioCarregado.acesso_ocorrencia
				|| this.props.sessao.id == 13 // Mauricio Junior Sfalcin
				|| this.props.sessao.id == 30 // Clarissa Brauner
				|| this.props.sessao.id == 23 // Alesandra Fatima da Silva Scheuer

				|| this.props.sessao.id == 48 // Bruna Schneider Padilha
				|| this.props.sessao.id == 31 // Alana Costa
				|| this.props.sessao.id == 58 // Vanessa Brizzi da Silva

				|| this.props.sessao.id == 23 // Alesandra Fatima da Silva Scheuer
				|| this.props.sessao.id == 67  // Agatha Nila Correa Maciel
				|| this.props.sessao.id == 68 // Arielli Jaqueline Gross Boeno
				|| this.props.sessao.id == 120 // Bruna Grazieli Barbosa

				|| this.props.sessao.id == 37 //Rodrigo Soares

			) {
				return true;
			} else if (
				item.id_usuario_inclusao == this.props.usuarioCarregado.id
				|| item.id_usuario_responsavel == this.props.usuarioCarregado.id
				|| item.id_usuario_aprovador == this.props.usuarioCarregado.id
			) {
				return true;
			}
			return false;
		});

		lista.sort((a, b) => new Date(a.datahora_inclusao).getTime() - new Date(b.datahora_inclusao).getTime());

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar Avulsa'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastroAvulsa', { 
										datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()), 
										quantidade: 1,
										avulsa: true
									});
									this.props.initForm({ 
										datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()), 
										quantidade: 1,
										avulsa: true
									});
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Ocorrências'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									datahora_inclusao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao),
									datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
									percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
									datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
									datahora_concluido: DateFormat.formatarDataHoraSqlParaTela(item.datahora_concluido),
									datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
									datahora_interacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_interacao),
									datahora_emissao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_emissao),
									datahora_entrega: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrega),
									custo: FormatUtils.formatarValorTela(item.custo, 2),
									valor: FormatUtils.formatarValorTela(item.valor, 2),
									valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2),
									nome_categoria: item.nome_categoria || 'Sem Categoria'
								}))} name='Ocorrências'>
									<ExcelColumn label='Número' value='id' />
									<ExcelColumn label='Tipo' value='tipo' />
									<ExcelColumn label='D/A' value='dias_aberto' />
									<ExcelColumn label='Status' value='nome_status' />
									<ExcelColumn label='ID Tipo de Ocorrência' value='id_tipo' />
									<ExcelColumn label='Tipo de Ocorrência' value='nome_tipo' />
									<ExcelColumn label='Início' value='datahora_inclusao' />
									<ExcelColumn label='Finalização' value='datahora_concluido' />
									<ExcelColumn label='Última Interação' value='datahora_interacao' />
									<ExcelColumn label='Tp.Doc.' value='tipo_documento' />
									<ExcelColumn label='Documento' value='numero_documento' />
									<ExcelColumn label='Ação Requerida' value='nome_acao' />
									<ExcelColumn label='Emissão' value='datahora_emissao' />
									<ExcelColumn label='Entrega' value='datahora_entrega' />
									<ExcelColumn label='Cliente' value='nome_cliente' />
									<ExcelColumn label='Destinatário' value='nome_destinatario' />
									<ExcelColumn label='Área Responsável' value='nome_ocorrencia_responsavel' />
									<ExcelColumn label='Responsável' value='nome_responsavel' />
									<ExcelColumn label='Motorista' value='nome_motorista' />
									<ExcelColumn label='Categoria' value='nome_categoria' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>

						<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista(true);
									this.props.getListaTipo();
									this.props.getListaMotorista();
									this.props.getListaGravidade();
									this.props.getListaLocal();
									this.props.getListaResponsabilidade();
									this.props.getListaTipoCusto();
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							name='tipo_periodo'
							options={[{ id: 'datahora_ocorrencia', valor: 'Data da Ocorrência' }, { id: 'datahora_inclusao', valor: 'Date de Início' }]}
							label='Período'
							cols='12 6 3 2'
							placeholder='Selecione o período'
							value={this.props.filtro.tipo_periodo}
							onChange={(value) => {
								this.props.setFiltro({ ...this.props.filtro, tipo_periodo: value });
							}} />

						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							}} />
				
						<Select
							name='tipo'
							options={[{ id: 'DOC', valor: 'Documento' }, { id: 'VPR', valor: 'VPR' }, { id: 'AVULSA', valor: 'Avulsa' }]}
							label='Tipo'
							cols='12 6 3 2'
							placeholder='Selecione o tipo'
							value={this.state.tipo}
							onChange={(value) => {
								this.setState({
									...this.state,
									tipo: value
								})
							}} />

						<Select
							name='id_situacao'
							options={[{ id: 1, valor: 'Todos' }, { id: 2, valor: 'Em Aberto' }, { id: 3, valor: 'Finalizado' }]}
							label='Situação'
							cols='12 4 2 2'
							placeholder='Selecione a situação'
							value={this.state.id_situacao}
							onChange={(value) => {
								this.setState({
									...this.state,
									id_situacao: value
								})
							}} />

						<Select
							name='id_status'
							options={this.props.listaStatus}
							label='Status'
							cols='12 4 2 2'
							placeholder='Selecione o status'
							value={this.state.id_status}
							onChange={(value) => {
								this.setState({
									...this.state,
									id_status: value
								})
							}} />
							
						<Select
							name='id_categoria'
							options={this.props.listaCategoria}
							label='Categoria'
							cols='12 4 2 2'
							placeholder='Selecione a categoria'
							value={this.state.id_categoria}
							onChange={(value) => {
								this.setState({
									...this.state,
									id_categoria: value
								})
							}} />
							
						<Select
							name='id_tipo'
							options={this.props.listaTipo.filter(item => {
								
								if (!item.documentacao) {
									return false;
								}

								return true;
							})}
							label='Tipo de Ocorrência'
							cols='12 6 3 2'
							placeholder='Selecione o tipo'
							value={this.state.id_tipo}
							onChange={(value) => {
								this.setState({
									...this.state,
									id_tipo: value
								})
							}} />
							
						<Select
							name='id_area'
							options={this.props.listaResponsabilidade}
							label='Área Responsável'
							cols='12 6 3 2'
							placeholder='Selecione a área'
							value={this.state.id_area}
							onChange={(value) => {
								this.setState({
									...this.state,
									id_area: value
								})
							}} />
							
						<Select
							name='id_motorista'
							options={this.props.listaMotorista}
							label='Motorista'
							cols='12 6 3 3'
							placeholder='Selecione o motorista'
							value={this.state.id_motorista}
							onChange={(value) => {
								this.setState({
									...this.state,
									id_motorista: value
								})
							}} />

						<Select
							name='id_interacao'
							options={[
								{ id: 1, valor: 'Todos' }, { id: 2, valor: 'Sem Interação' }, { id: 3, valor: 'Com Interação' }, 
								{ id: 4, valor: '5 dias' }, { id: 5, valor: '10 dias' }, { id: 6, valor: '15 dias' }
							]}
							label='Interação'
							cols='12 6 3 3'
							placeholder='Selecione a última interação'
							value={this.state.id_interacao}
							onChange={(value) => {
								this.setState({
									...this.state,
									id_interacao: value
								})
							}} />

					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive tableBordered>
						<THead>
							<Tr>
								<Th width={50}></Th>
								<Th width={5} alignCenter title={'Categoria'}>Cat.</Th>
								<Th width={5} alignCenter><i class='fas fa-paperclip' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Digital anexado'} /></Th>
								<Th width={5} alignCenter>Número</Th>
								<Th width={5} alignCenter>Tipo</Th>
								<Th width={5} alignCenter>D/A</Th>
								<Th width={100} alignCenter>Status</Th>
								<Th width={200} alignCenter>Tipo de Ocorrência</Th>
								<Th width={50} alignCenter>Início</Th>
								<Th width={50} alignCenter>Finalização</Th>
								<Th width={50} alignCenter>Última Interação</Th>
								<Th width={100} alignCenter>Tp.Doc.</Th>
								<Th width={100} alignCenter>Documento</Th>
								<Th width={100} alignCenter>Ação Requerida</Th>
								<Th width={50} alignCenter>Emissão</Th>
								<Th width={50} alignCenter>Entrega</Th>
								<Th width={100} alignCenter>Cliente</Th>
								<Th width={100} alignCenter>Destinatário</Th>
								<Th width={100} alignCenter>Área Responsável</Th>
								<Th width={100} alignCenter>Responsável</Th>
								<Th width={100} alignCenter>Motorista</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter minWidth={60}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={true}
											small
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga),
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_concluido),
													datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
													custo: FormatUtils.formatarValorTela(item.custo, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
												});
												this.props.initForm({
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga),
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_concluido),
													datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
													custo: FormatUtils.formatarValorTela(item.custo, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={true}
											small
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga),
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_concluido),
													datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
													custo: FormatUtils.formatarValorTela(item.custo, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
												});
												this.props.initForm({
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga),
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_concluido),
													datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
													custo: FormatUtils.formatarValorTela(item.custo, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
												});
											}} />
									</Td>
									<Td alignCenter title={item.nome_categoria || 'Sem Categoria'}>
										<i class='fas fa-circle' title={item.nome_categoria || 'Sem Categoria'} style={{ fontSize: 18, color: item.cor_categoria || '#bfbfbf' }}></i>
									</Td>
									<Td alignCenter minWidth={5} small noWrap>
										{(item.arquivo || []).length > 0 &&
										<i class='fas fa-paperclip' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Digital anexado'} />}
									</Td>
									<Td alignCenter minWidth={5} small noWrap>{item.id}</Td>
									<Td alignCenter minWidth={5} small noWrap>{item.tipo}</Td>
									<Td alignCenter minWidth={5} small noWrap>{item.dias_aberto}</Td>
									<Td alignCenter minWidth={100} small noWrap>{item.nome_status}</Td>
									<Td alignCenter minWidth={200} small noWrap>{item.id_tipo} - {item.nome_tipo}</Td>
									<Td alignCenter minWidth={50} small>{DateFormat.formatarDataSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter minWidth={50} small>{DateFormat.formatarDataSqlParaTela(item.datahora_concluido)}</Td>
									<Td alignCenter minWidth={50} small
										color={(item.datahora_interacao && item.datahora_interacao == item.datahora_interacao_responsavel) ? (item.datahora_interacao > item.datahora_comentario_visto || !item.datahora_comentario_visto ? '#ff0000' : null) : null}
										>{(item.datahora_interacao && item.datahora_interacao == item.datahora_interacao_responsavel) ? (item.datahora_interacao > item.datahora_comentario_visto || !item.datahora_comentario_visto ? <i class='fas fa-bell'></i> : null) : null} {DateFormat.formatarDataSqlParaTela(item.datahora_interacao)}</Td>
									<Td alignCenter minWidth={100} small noWrap>{item.tipo_documento}</Td>
									<Td alignCenter minWidth={100} small noWrap>{item.numero_documento}</Td>
									<Td alignCenter minWidth={100} small noWrap>{item.nome_acao}</Td>
									<Td alignCenter minWidth={50} small>{DateFormat.formatarDataSqlParaTela(item.datahora_emissao)}</Td>
									<Td alignCenter minWidth={50} small>{DateFormat.formatarDataSqlParaTela(item.datahora_entrega)}</Td>
									<Td alignCenter minWidth={100} small noWrap>{item.nome_cliente}</Td>
									<Td alignCenter minWidth={100} small noWrap>{item.nome_destinatario}</Td>
									<Td alignCenter minWidth={100} small noWrap>{item.nome_ocorrencia_responsavel}</Td>
									<Td alignCenter minWidth={100} small noWrap>{item.nome_responsavel}</Td>
									<Td alignCenter minWidth={100} small noWrap>{item.nome_motorista}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.ocorrencia.modoTela,
	lista: state.ocorrencia.lista,
	filtro: state.ocorrencia.filtro,
	listaTipo: state.ocorrencia.listaTipo,
	listaMotorista: state.ocorrencia.listaMotorista,
	listaResponsabilidade: state.ocorrencia.listaResponsabilidade,
	listaStatus: state.ocorrencia.listaStatus,
	listaCategoria: state.ocorrencia.listaCategoria
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, initForm, salvar, excluir, getLista, 
	getListaTipo, getListaMotorista, getListaGravidade, getListaLocal, 
	getListaResponsabilidade, getListaTipoCusto, salvarAbona, 
	getListaStatus, getListaAcao, getListaUsuario, getListaCategoria
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OcorrenciaDocumentoViagem);
